var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-address" },
    [
      _c("HeadNav"),
      _c("div", { staticClass: "cell-group" }, [
        _c("div", { staticClass: "cell" }, [
          _c("span", { staticClass: "form-label" }, [_vm._v("收货人")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.real_name,
                expression: "real_name",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "请填写收货人姓名",
              maxlength: "10",
            },
            domProps: { value: _vm.real_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.real_name = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "cell" }, [
          _c("span", { staticClass: "form-label" }, [_vm._v("联系电话")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mobile,
                expression: "mobile",
              },
            ],
            attrs: {
              type: "tel",
              placeholder: "请填写联系电话号码",
              maxlength: "11",
            },
            domProps: { value: _vm.mobile },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.mobile = $event.target.value
              },
            },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "area-box df fdr alc",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showArea.apply(null, arguments)
              },
            },
          },
          [
            _c("span", { attrs: { slot: "left" }, slot: "left" }, [
              _vm._v("省市区"),
            ]),
            _vm.area_string
              ? _c("div", { staticClass: "area-string df alc" }, [
                  _vm._v("\n        " + _vm._s(_vm.area_string) + "\n      "),
                ])
              : _c("div", { staticClass: "no-area" }, [_vm._v("请选择")]),
          ]
        ),
        _c(
          "div",
          { staticClass: "cell", staticStyle: { "margin-bottom": "0.24rem" } },
          [
            _c("span", { staticClass: "form-label" }, [_vm._v("详细地址")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.address,
                  expression: "address",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "请填写详细地址",
                maxlength: "30",
              },
              domProps: { value: _vm.address },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.address = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "select-box" },
        [
          _c(
            "yd-cell-group",
            [
              _c(
                "yd-cell-item",
                { attrs: { type: "label" } },
                [
                  _c("div", { attrs: { slot: "left" }, slot: "left" }, [
                    _vm._v("设为默认地址"),
                  ]),
                  _c("yd-switch", {
                    attrs: { slot: "right" },
                    slot: "right",
                    model: {
                      value: _vm.is_default,
                      callback: function ($$v) {
                        _vm.is_default = $$v
                      },
                      expression: "is_default",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectAddress", {
        ref: "address",
        attrs: { edit_date: _vm.edit_date },
        on: { confirmAddress: _vm.confirmAddress },
      }),
      _c(
        "footer",
        { on: { click: _vm.newAddress } },
        [_c("MyButton", { attrs: { title: "保存", is_disabled: false } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }