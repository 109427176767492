<template>
  <div class="new-address">
    <!--<yd-cell-group>
      <yd-cell-item>
        <span slot="left">收货人</span>
        <yd-input slot="right" v-model="real_name" required :show-success-icon="false" :show-error-icon="false"
                  :show-clear-icon="false" max="10" placeholder="请填写收货人姓名"></yd-input>
      </yd-cell-item>
      <yd-cell-item>
        <span slot="left">联系电话</span>
        <yd-input slot="right" v-model="mobile" required :show-success-icon="false" :show-error-icon="false"
                  regex="mobile" :show-clear-icon="false" placeholder="请填写联系电话号码"></yd-input>
      </yd-cell-item>
      &lt;!&ndash;省市区四级联动&ndash;&gt;
      <div class="area-box df fdr alc" @click.stop="showArea">
        <span slot="left">省市区</span>
        <div class="area-string df alc" v-if="area_string">{{area_string}}</div>
        <div class="no-area" v-else>请选择</div>
      </div>
      <yd-cell-item>
        <span slot="left">详细地址</span>
        <yd-input slot="right" v-model="address" required :show-success-icon="false" :show-error-icon="false"
                  :show-clear-icon="false" max="20" placeholder="请填写详细地址"></yd-input>
      </yd-cell-item>
    </yd-cell-group>-->
    <HeadNav/>
    <div class="cell-group">
      <div class="cell">
        <span class="form-label">收货人</span>
        <input
          type="text"
          v-model="real_name"
          placeholder="请填写收货人姓名"
          maxlength="10"
        />
      </div>
      <div class="cell">
        <span class="form-label">联系电话</span>
        <input
          type="tel"
          v-model="mobile"
          placeholder="请填写联系电话号码"
          maxlength="11"
        />
      </div>
      <!--省市区四级联动-->
      <div class="area-box df fdr alc" @click.stop="showArea">
        <span slot="left">省市区</span>
        <div class="area-string df alc" v-if="area_string">
          {{ area_string }}
        </div>
        <div class="no-area" v-else>请选择</div>
      </div>
      <div class="cell" style="margin-bottom: 0.24rem;">
        <span class="form-label">详细地址</span>
        <input
          type="text"
          v-model="address"
          placeholder="请填写详细地址"
          maxlength="30"
        />
      </div>
    </div>
    <div class="select-box">
      <yd-cell-group>
        <yd-cell-item type="label">
          <div slot="left">设为默认地址</div>
          <yd-switch slot="right" v-model="is_default"></yd-switch>
        </yd-cell-item>
      </yd-cell-group>
    </div>
    <selectAddress
      ref="address"
      :edit_date="edit_date"
      @confirmAddress="confirmAddress"
    >
    </selectAddress>
    <footer @click="newAddress">
      <MyButton title="保存" :is_disabled="false"></MyButton>
    </footer>
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import MyButton from "@/components/button/button";
import {
  addUserAddress,
  editUserAddress,
  getUserAddressDetail,
  getProvinceList,
  getCityList,
  getAreaList,
  getStreetList
} from "@/services/my";
import selectAddress from "@/components/common/selectAddress";
import HeadNav from "@/components/common/HeadNav";

export default {
  name: "NewAddress",
  components: {
    MyButton,
    selectAddress,
    HeadNav
  },
  data() {
    return {
      edit_id: "",
      real_name: "", //收货人
      mobile: "", //手机号
      province_id: "",
      city_id: "",
      area_id: "",
      street_id: "",
      area_string: "", //省市区街道
      address: "",
      district: [],
      is_default: "", //是否是默认地址
      select_list: [],
      edit_date: "", //回显地址
      add_confirm: false //是否通过二次确认
    };
  },

  mounted() {
    let that = this;
    that.getParams();
  },
  methods: {
    getParams() {
      this.edit_id = this.$route.query.edit_id ? this.$route.query.edit_id : "";
      if (this.edit_id) {
        document.title = "编辑收货地址";
        this.getUserAddressDetail();
      }
    },
    showArea() {
      this.$refs.address.choseAdd();
    },
    confirmAddress(params) {
      console.log(params,'LLL');
      this.province_id = params.province_id;
      this.city_id = params.city_id;
      this.area_id = params.area_id;
      this.street_id = params.street_id;
      this.area_string = params.area_string;
    },
    async getUserAddressDetail() {
      try {
        const res = await getUserAddressDetail(this.edit_id);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.real_name = data.realname;
          this.mobile = data.mobile;
          this.province_id = data.province_id;
          this.city_id = data.city_id;
          this.area_id = data.area_id;
          this.street_id = data.street_id;
          this.area_string = data.area_string;
          this.address = data.address;
          this.is_default = !!data.is_default;
          this.edit_date = {
            province_id: data.province_id,
            city_id: data.city_id,
            area_id: data.area_id,
            street_id: data.street_id,
            area_string: data.area_string
          };
        }
      } catch (e) {}
    },
    async newAddress() {
      try {
        let param = {
          realname: this.real_name,
          mobile: this.mobile,
          province_id: this.province_id,
          city_id: this.city_id,
          area_id: this.area_id,
          street_id: this.street_id,
          area_string: this.area_string,
          address: this.address,
          is_default: this.is_default ? "1" : "0"
        };
        const { result, errorMessage } = Validator.validate(
          "mobile",
          param.mobile
        );
        if (!param.realname) {
          this.$dialog.toast({
            mes: "请填写收货人姓名"
          });
          return;
        }
        if (!param.mobile) {
          this.$dialog.toast({
            mes: "请填写联系电话号码"
          });
          return;
        }
        if (!result) {
          this.$dialog.toast({
            mes: errorMessage
          });
          return;
        }
        if (!param.province_id) {
          this.$dialog.toast({
            mes: "请选择省市区",
            timeout: 1500
          });
          return;
        }
        if (!param.area_string) {
          this.$dialog.toast({
            mes: "请选择省市区",
            timeout: 1500
          });
          return;
        }
        if (!param.address) {
          this.$dialog.toast({
            mes: "请填写详细地址",
            timeout: 1500
          });
          return;
        }
        if (!this.add_confirm) {
          this.$dialog.confirm({
            title: "亲,请核对您的收货信息",
            mes: `姓名：${this.real_name}</br>
            电话：${this.mobile}</br>
            地址：${this.area_string + this.address}
            </br>`,
            opts: [
              {
                txt: "取消",
                color: "#353535"
              },
              {
                txt: "确定",
                color: true,
                callback: async () => {
                  this.add_confirm = true;
                  if (this.edit_id) {
                    param.id = this.edit_id;
                    const res = await editUserAddress(param);
                    if (res.code === 0) {
                      this.$dialog.toast({
                        mes: "编辑成功",
                        icon: "success",
                        timeout: 1500
                      });
                      if (!this.is_default) {
                        this.$store.commit("clearAddress");
                      }
                      this.$router.go(-1);
                    }
                  } else {
                    const res = await addUserAddress(param);
                    if (res.code === 0) {
                      this.$dialog.toast({
                        mes: "添加成功",
                        icon: "success",
                        timeout: 1500
                      });
                      this.$router.go(-1);
                    }
                  }
                }
              }
            ]
          });
          return;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";
.yd-confirm-bd {
  margin: 0.4rem 0;
}
.new-address {
  .cell-group {
    width: 100%;
    padding-left: 0.24rem;
    background: #fff;
    .cell {
      .wh(100%, 0.96rem);
      line-height: 0.96rem;
      border-bottom: 1px solid #d8d8d8;
      overflow: hidden;
      span {
        .sc(0.32rem, #333);
        width: 22%;
        display: inline-block;
      }
      input {
        width: 78%;
        height: 0.96rem;
        .sc(0.32rem, #666);
        &::placeholder {
          .sc(0.32rem, @color-light-grey);
        }
      }
    }
  }

  .yd-cell-left {
    .sc(0.32rem, @color-dark-grey);
    width: 19.1%;
    margin-right: 0.24rem;
    span {
      line-height: 0.96rem;
    }
  }

  .yd-cell-right {
    height: 0.96rem;
    width: 80.9%;

    input {
      .sc(0.32rem, @color-grey);
      line-height: 100% !important;
      height: 100%;

      &::placeholder {
        .sc(0.32rem, @color-light-grey);
      }
    }
  }
  .yd-cell-right input[type="text"] {
    height: 0.96rem;
    line-height: 0.96rem !important;
  }

  .yd-cell:after {
    height: 0;
  }

  /*设为默认*/

  .select-box {
    input {
      color: var(--main-color) !important;
      width: 1.02rem;
      height: 0.62rem;
      border-radius: 0.31rem;
    }

    .yd-switch:after {
      .wh(0.56rem, 0.56rem);
    }
  }

  .area-box {
    .sc(0.32rem, @color-dark-grey);
    min-height: 0.96rem;
    padding-right: 0.56rem;
    background: white url(../../../assets/ic_arrow_right_gray@2x.png) no-repeat
      97% 50%;
    background-size: 0.26rem 0.26rem;
    border-bottom: 1px solid #ddd;

    span {
      width: 23.5%;
    }

    .area-string,
    no-area {
      height: 100%;
      width: 79.2%;
      background: @color-white;
      border: none;
      line-height: 0.44rem;
      color: #666;
    }

    .no-area {
      color: @color-light-grey;
    }
  }

  .yd-cell-box {
    margin-bottom: 0.24rem;
  }

  footer {
    margin-top: 0.6rem;
  }
}
</style>
